import { graphql, navigate } from 'gatsby';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import React, { ReactElement } from 'react';

import Link from 'ui-kit/link/link';

import AuthMobileNav from 'components/auth-mobile-nav';
// Types
import { AuthMobileNavProps } from 'components/auth-mobile-nav/auth-mobile-nav.props';
import WorkflowLayout from 'components/layouts/workflow/workflow.layout';
import WorkflowFooterLinks from 'components/workflow-footer-links/workflow-footer-links';
// UI Kit & Components
import WorkflowLayoutFormWrapper from 'components/workflow-layout-form-wrapper/workflow-layout-form-wrapper.component';

const LoginIssues = ({
    location,
    data
}: {
    location: any;
    data: GatsbyTypes.LoginIssuesPageDataQuery;
}): ReactElement => {
    const { t } = useTranslation();

    // Footer links selector
    const workflowFooterLinks =
        data.allBlockContentRegistrationFlowFooterLinks?.edges?.[0]?.node?.field_registration_footer_link ?? [];

    const handleAuthMobileNavBackClick = React.useCallback<NonNullable<AuthMobileNavProps['onBackButtonClick']>>(() => {
        navigate('/sign-in');
    }, []);

    return (
        <WorkflowLayout
            backgroundImage={data.blueSkyBackground}
            useRoundedCorners={false}
            metaData={{ nodeTitle: t('loginIssues.labels.eyebrowText') }}
            anonymousOnly={true}
        >
            <WorkflowLayoutFormWrapper
                currentFlow={'login'}
                eyebrowText={t('loginIssues.labels.eyebrowText')}
                title={t('loginIssues.labels.title')}
                nav={<AuthMobileNav onBackButtonClick={handleAuthMobileNavBackClick} />}
            >
                <div className="d-flex flex-column align-items-center">
                    <Link
                        to="/forgot-username"
                        label={t('loginIssues.labels.forgotUsername')}
                        dataGALocation="ForgotUsername"
                        className="sm-full mt-4 text-center"
                        anchorClassName="btn btn-primary text-white"
                    />
                    <Link
                        to="/forgot-password"
                        label={t('loginIssues.labels.forgotPassword')}
                        dataGALocation={'ForgotPassword'}
                        className="sm-full mt-4 text-center"
                        anchorClassName="btn btn-primary text-white"
                    />
                </div>
            </WorkflowLayoutFormWrapper>
            {/* Workflow links */}
            <WorkflowFooterLinks links={workflowFooterLinks as any} locationGAflow="login-issues" />
        </WorkflowLayout>
    );
};

export default LoginIssues;

export const query = graphql`
    query LoginIssuesPageData($language: String!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
        blueSkyBackground: file(relativePath: { eq: "assets/images/bird-on-white-background.jpg" }) {
            id
            childImageSharp {
                gatsbyImageData(formats: [AUTO])
            }
        }
        allBlockContentRegistrationFlowFooterLinks(
            filter: { field_registration_flow_types: { eq: "login_issues_flow" } }
        ) {
            edges {
                node {
                    field_registration_footer_link {
                        title
                        uri
                    }
                }
            }
        }
    }
`;
